import React, { useState, useEffect } from 'react';
import './recargar-styles.css';
import {MenuApp, MenuCliente} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {recargasImg, logoBinance, banco} from '../../../global/imgs';
import Swal from 'sweetalert2';
import {consultarCompras} from '../../../backend/recargar/recargar';
import {ListaPaises} from '../../../components/paises/paises';
import {AnimatedPage} from '../../../components/animacion/animacion';

function ClienteRecargarScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [pagoBinance, CambioPagoBinance] = useState(false);
	const [monto, CambioMonto] = useState('');
	const [tipoPago, CambioTipoPago] = useState('');
	const [compras, CambioCompras] = useState([]);
	const [comprasFinales, CambioComprasFinales] = useState([]);
	const [limiteCompras, CambioLimiteCompras] = useState(20);

	useEffect(() =>{

		if (usuarioBuscado == false) {

			ponerUsuario();
			CambioUsuarioBuscado(true);
		}
	})

	const ponerUsuario = async function(){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

			if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

				window.location.href = '/';
			}else{

				let lasCompras = await consultarCompras(elUsuario.uid); console.log(lasCompras);

				let lasCompras2 = [];

				let cero = 0;

	            lasCompras.forEach(element =>{

	              if (element.data.tipo == 'creditos') {

	                if (cero <= limiteCompras) {
	                  lasCompras2.push(element);
	                }
	              }

	              cero++;
	            });

	            if (lasCompras.length > 20) {
	              document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
	            }

	            CambioCompras(lasCompras);
	            CambioComprasFinales(lasCompras2);
			}
		}else{

			window.location.href = '/';
		}
	}

	const seleccionarTipoPago = async function(tipo){

		if (tipo == 'pagoBinance') {

			document.getElementById('pagoBinance').classList.add('bordePago');
			document.getElementById('pagoBanco').classList.remove('bordePago');
			CambioTipoPago(tipo);
		}else if (tipo == 'pagoBanco') {

			document.getElementById('pagoBinance').classList.remove('bordePago');
			document.getElementById('pagoBanco').classList.add('bordePago');
			CambioTipoPago(tipo);
		}
	}

	const procesarPago = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

		if (tipoPago === '' || monto === '') {

			Swal.fire({
	            icon: 'info',
	            title: 'Complete todos los campos para continuar.',
	        });
		}else{

			if (tipoPago == 'pagoBinance') {

				window.location.href = 'http://localhost/venet-go-play-backend/binance/prueba.php';
			}else if (tipoPago == 'pagoBanco') {

				window.location.href = "https://api.whatsapp.com/send?phone=584120445551&text=Hola,%20deseo%20pagar%20con%20transfencia%20bancaria%20el%20monto%20de:%20"+monto+"%20creditos.";
			}
		}
	}

	const verMas = async function(){

	    /*let porTipo = document.getElementById('tipoMostrar').value;

	    let limite = limiteUsuarios + 20;

	    let losUsuariosCliente = [];
	    let losUsuariosAdmin = [];

	    let cero = 0;

	    usuarios.forEach(element =>{

	      if (element.data.tipo == 'cliente') {

	        if (cero <= limite) {
	          losUsuariosCliente.push(element);
	        }
	      }else if (element.data.tipo == 'subAdmin') {

	        if (cero <= limite) {
	          losUsuariosAdmin.push(element);
	        }
	      }

	      cero++;
	    });


	    CambioUsuariosCliente(losUsuariosCliente);
	    CambioUsuariosAdmin(losUsuariosAdmin);
	    CambioLimiteUsuarios(limite);*/
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuCliente/>
				<div class="container">

					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={recargasImg}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">RECARGAR</h4>
							</div>
						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<h6 class="tituloSeccion">TIPOS DE PAGO</h6>

							<div class="containerBotonesPago">

								<div onClick={() => seleccionarTipoPago('pagoBinance')} id="pagoBinance" class="botonBinance">
									<img
										src={logoBinance}
										class="logoBinance"
									/>
									<span class="textoBinance">BINANCE</span>
								</div>

								<div onClick={() => seleccionarTipoPago('pagoBanco')} id="pagoBanco" class="botonBanco">
									<img
										src={banco}
										class="logoBinance"
									/>
									<span class="textoBanco">TRANFERENCIA<br/>BANCARIA</span>
								</div>

							</div>

						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<ListaPaises
								mostrar={tipoPago}
							/>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<div class="form" style={{margin: 0,}}>
				                <div class="headerFormLogin">
				                    <img
				                      src={recargasImg}
				                      class="userPlus"
				                    />
				                    <h4 id="tituloHeaderLogin" class="tituloHeaderLogin">MONTO</h4>
				                </div>

					            <div class="mb-3 containerInput">
					                <input onChange={() => CambioMonto(document.getElementById('monto').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="monto" placeholder="Monto *" />
					            </div>

				                <button onClick={procesarPago} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
				                  	IR A PAGAR
				                </button>
				            </div>

						</div>
					</div><br/>

					<div class="row">
						<div class="col-sm">

							<table class="table">
								  <thead>
								    <tr>
								      <th scope="col">ID Orden</th>
								      <th scope="col">Metodo de pago</th>
								      <th scope="col">Fecha</th>
								      <th scope="col">Cantidad de creditos</th>
								    </tr>
								  </thead>
								  <tbody>
								  	{comprasFinales.map((item) =>(
								  		<tr>
									      <td>{item.data.idOrden}</td>
									      <td>{item.data.metodoDePago}</td>
									      <td>{item.data.fecha}</td>
									      <td>{item.data.datosCompra.creditos}</td>
									    </tr>
								  	))}
								  </tbody>
							</table>

						</div>
					</div><br/>

					<div class="row">
			            <div class="col-sm">

			              <button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
			                Ver más
			              </button><br/><br/>

			            </div>
			        </div>

				</div>
			</div>
		</AnimatedPage>
	);
}

export default ClienteRecargarScreen;
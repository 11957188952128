import React, { useState, useEffect } from 'react';
import './revendedor-styles.css';
import {MenuApp, MenuCliente} from '../../../components/menu/menu';
import {consultarProductos, procesarCompra} from '../../../backend/productos/productos';
import {validarUsuario} from '../../../global/validarUsuario';
import {carritoBlanco, paisesImg, digitalImg, relojImg, spotify, monitorImg, devices} from '../../../global/imgs';
import {Modal} from '../../../components/modals/modals';
import {Temporizador, CantidadStock, InfoRegion, NumeroCompras} from '../../../components/productos/productos';
import Swal from 'sweetalert2';
import {AnimatedPage} from '../../../components/animacion/animacion';

function ClienteRevendedorScreen(){

	const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
	const [productos, CambioProductos] = useState([]);
  	const [productosFinales, CambioProductosFinales] = useState([]);
  	const [limiteProductos, CambioLimiteProductos] = useState(20);
  	const [busquedaProductos, CambioBusquedaProductos] = useState(false);
	const [tarjetasRegalos, CambioTarjetasRegalos] = useState([]);
	const [modalComprarProducto, CambioModalComprarProducto] = useState(false);
	const [datosProducto, CambioDatosProducto] = useState(false);
	const [idProducto, CambioIdProducto] = useState('');
	const [fotoProducto, CambioFotoProducto] = useState('');
	const [nombreProducto, CambioNombreProducto] = useState('');
	const [regionProducto, CambioRegionProducto] = useState('');
	const [precioProducto, CambioPrecioProducto] = useState(0);
	const [tiempoCompra, CambioTiempoCompra] = useState('');
	const [tipoCuenta, CambioTipoCuenta] = useState('');
	const [mostrarModalTerminos, CambioMostrarModalTerminos] = useState(false);

	const [mostrarModalPreguntaComprar, CambioMostrarModalPreguntaComprar] = useState(false);

	useEffect(() =>{

	    if (usuarioBuscado == false) {

	      ponerUsuario();
	      CambioUsuarioBuscado(true);
	    }

		if (busquedaProductos == false) {

		      consultarProductos().then(response =>{

		        let masProductos = [];
		        let lasTarjetas = [];

		        let cero = 0;

		        response.forEach(element =>{
		          if (cero <= limiteProductos) {

		          	if (element.data.tipo == 'tarjeta') {

		          		lasTarjetas.push(element);
		          	}else{

		          		masProductos.push(element);
		          	}
		          }
		          cero++;
		        });

		        CambioProductos(response);
		        CambioProductosFinales(masProductos);
		        CambioTarjetasRegalos(lasTarjetas);
		      });

		      CambioBusquedaProductos(true);
		}

		if (productosFinales.length > 0) {

			document.getElementById('rowLoading').setAttribute('style', 'display: none;');
		}
	})

	const ponerUsuario = async function(){

	    let elUsuario = await validarUsuario();

	    if (elUsuario != false) {

		    if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

		      	window.location.href = '/admin';
		    }else if (elUsuario.revendedor == false) {

		      	window.location.href = '/cliente';
		    }
	    }else{

	    	window.location.href = '/cliente';
	    }
	}

	const comprarProducto = async function(item){

		let elUsuario = await validarUsuario();

		if (elUsuario != false) {

	      	if (item.data.tipo == 'cuenta') {

				CambioModalComprarProducto(true);
			}else if (item.data.tipo == 'tarjeta') {

				CambioMostrarModalPreguntaComprar(true);
				CambioPrecioProducto(item.data.precioRevendedor);
			}
			
			CambioDatosProducto(item);
			CambioIdProducto(item.id);
			CambioFotoProducto(item.data.foto);
			CambioNombreProducto(item.data.nombre);
			CambioRegionProducto(item.data.region);
	    }else{

	    	window.location.href = '/auth/ingresar';
	    }
	}

	const elegirTiempo = async function(tiempo){

		if (tiempo == '10 Días') {

			document.getElementById('10Dias').classList.add('laOpcionComprarActive');
			document.getElementById('15Dias').classList.remove('laOpcionComprarActive');
			document.getElementById('1Mes').classList.remove('laOpcionComprarActive');
		}else if (tiempo == '15 Días') {

			document.getElementById('10Dias').classList.remove('laOpcionComprarActive');
			document.getElementById('15Dias').classList.add('laOpcionComprarActive');
			document.getElementById('1Mes').classList.remove('laOpcionComprarActive');
		}else if (tiempo == '1 Mes') {

			document.getElementById('10Dias').classList.remove('laOpcionComprarActive');
			document.getElementById('15Dias').classList.remove('laOpcionComprarActive');
			document.getElementById('1Mes').classList.add('laOpcionComprarActive');
		}

		CambioTiempoCompra(tiempo);
	}

	const pagarProducto = async function(){

		Swal.fire({
	        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
	            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
	            '</div>',
	        showConfirmButton: false,
	        allowOutsideClick: false,
	        allowEnterKey: false,
	        allowEscapeKey: false,
	    });

	    let terminosCondiciones = '';

	    let validarCampos = true;

	    if (datosProducto.data.tipo == 'cuenta') {

	    	terminosCondiciones = document.getElementById('flexCheckDefault').checked;

	    	if (tiempoCompra === '' || terminosCondiciones == false || tipoCuenta === '') {

		    	validarCampos = true;
		    }
	    }

	    if (validarCampos == false) {

	    	Swal.fire({
			    icon: 'info',
			    title: 'Seleccione el tiempo de que quiere disponer del producto.',
			});
	    }else{

	    	let elUsuario = await validarUsuario();

			if (elUsuario != false) {

				procesarCompra(elUsuario, datosProducto, tiempoCompra, 'revendedor', tipoCuenta).then(response =>{
					
					if (response.resultado != false) {

						window.location.href = '/cliente/pedidos';
					}else{

						Swal.fire({
						    icon: 'info',
						    title: response.mensaje,
						});
					}
				})
			}else{

		    	window.location.href = '/auth/ingresar';
		    }
	    }
	}

	const elegirTipoCuenta = async function(tipo){

		CambioTipoCuenta(tipo);

		if (tipo == '1 Pantalla') {


			CambioPrecioProducto(datosProducto.data.precioRevendedor);

			document.getElementById('1Pantalla').classList.add('laOpcionComprarActive');
			document.getElementById('cuentaCompleta-'+idProducto).classList.remove('laOpcionComprarActive');	
		}else if (tipo == 'Cuenta Completa') {

			CambioPrecioProducto(datosProducto.data.precioCuentaCompletaRevendedor);

			document.getElementById('1Pantalla').classList.remove('laOpcionComprarActive');
			document.getElementById('cuentaCompleta-'+idProducto).classList.add('laOpcionComprarActive');
		}
	}

	return(
		<AnimatedPage duracion={0.5}>
			<MenuApp/>
			<div class="gridApp">
				<MenuCliente/>
				<div class="container">

					<div class="row">
						<div class="col-sm">
							<div class="containerTituloSeccion">
								<img
									src={spotify}
									class="imgTituloSeccion"
								/>
								<h4 class="tituloSeccion">REVENDEDOR</h4>
							</div>
						</div>
					</div><br/>

					<div class="row" id="rowLoading">
						<div class="col-sm">

							<div class="profile-main-loader">
							  <div class="loader">
							    <svg class="circular-loader"viewBox="25 25 50 50" >
							      <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#3ad32c" stroke-width="2" />
							    </svg>
							  </div>
							</div>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<h1 class="tituloBusqueda">PLATAFORMA STREAMING</h1>

							<div class="productosHome">
								{productosFinales.map((item) =>(
									<div class="producto" style={{background: 'linear-gradient(320deg, #0a0a0a 0%, '+item.data.color+' 100%)',}}>
										<p class="estrellas">★ ★ ★ ★ ★</p>
										<p class="textoCantidadVendido"><NumeroCompras idProducto3={item.id} /> personas han comprado este servicio.</p>
										<CantidadStock
											producto={item}
										/>
										<style id={"estilosBotonCuentaCompleta-"+item.id}>
										</style>
										<img
											src={item.data.foto}
											class="imgProducto"
										/>
										<InfoRegion 
											idPais={item.data.region}
											tipoInfo={'producto'}
										/>
										<span style={{color: '#fff', fontWeight: 'bold',}}>{item.data.nombre}</span>
										<div class="precioDescuentoContainer">
											<div class="precio" style={{background: 'linear-gradient(320deg, #0a0a0a 0%, '+item.data.color+' 100%)',}}>
												${item.data.precioRevendedor}
											</div>
											<p class="descuento">50%</p>
										</div>
										<div id={'botonComprar-'+item.id} class="precioDescuentoContainer">
											<div>
												
											</div>
											<div onClick={() => comprarProducto(item)} class="btn btn-primary" style={{background: '#0020c7',}}>
												<img
													src={carritoBlanco}
													style={{width: 30,}}
												/>
												Comprar
											</div>
										</div>
									</div>
								))}
							</div>

						</div>
					</div>

					<div class="row">
						<div class="col-sm">

							<h1 class="tituloBusqueda">TARJETAS DE REGALO</h1>

							<div class="productosHome">
								{tarjetasRegalos.map((item) =>(
									<div class="producto" style={{background: 'linear-gradient(320deg, #0a0a0a 0%, '+item.data.color+' 100%)',}}>
										<p class="estrellas">★ ★ ★ ★ ★</p>
										<p class="textoCantidadVendido"><NumeroCompras idProducto3={item.id} /> personas han comprado este servicio.</p>
										<CantidadStock
											producto={item}
										/>
										<style id={"estilosBotonCuentaCompleta-"+item.id}>
										</style>
										<img
											src={item.data.foto}
											class="imgProducto"
										/>
										<InfoRegion 
											idPais={item.data.region}
											tipoInfo={'producto'}
										/>
										<span style={{color: '#fff', fontWeight: 'bold',}}>{item.data.nombre}</span>
										<div class="precioDescuentoContainer">
											<div class="precio" style={{background: 'linear-gradient(320deg, #0a0a0a 0%, '+item.data.color+' 100%)',}}>
												${item.data.precioRevendedor}
											</div>
											<p class="descuento">50%</p>
										</div>
										<div id={'botonComprar-'+item.id} class="precioDescuentoContainer">
											<div>
												
											</div>
											<div onClick={() => comprarProducto(item)} class="btn btn-primary" style={{background: '#0020c7',}}>
												<img
													src={carritoBlanco}
													style={{width: 30,}}
												/>
												Comprar
											</div>
										</div>
									</div>
								))}
							</div>

						</div>
					</div>

				</div>
			</div>

			<Modal mostrar={modalComprarProducto}>
			    <p onClick={() => {
			    	CambioModalComprarProducto(false);
			    	CambioDatosProducto(false);
			    	CambioIdProducto('');
			    	CambioFotoProducto('');
					CambioNombreProducto('');
					CambioRegionProducto('');
					CambioPrecioProducto(0);
					CambioTiempoCompra('');
					CambioTipoCuenta('');
			    }} class="equix">X</p>
			    <div class="modalComprarProducto">

			    	<div class="productoCompra">

						<img
							src={fotoProducto}
							class="fotoProductoBusqueda"
						/>
						<span>{nombreProducto}</span>

					</div>

					<div class="detallesProducto">

						<div class="elDetalleProducto">
							<div class="imagenDetalleProducto">
								<img
									src={paisesImg}
									class="imgDetalleProducto"
								/>
							</div>
							<span class="tituloDetalleProducto">
								<InfoRegion 
									idPais={regionProducto}
									tipoInfo={'carrito'}
								/>
							</span>
						</div>

						<div class="elDetalleProducto">
							<div class="imagenDetalleProducto">
								<img
									src={digitalImg}
									class="imgDetalleProducto"
								/>
							</div>
							<span class="tituloDetalleProducto">Tipo: Digital</span>
						</div>

					</div>

					<div class="opcionesProducto">

						<h5 class="tituloOpcionesProducto">Cuanto tiempo desea comprar?</h5>
						<div class="hr"></div>

						<div class="opcionesElegir">

							<div id="10Dias" onClick={() => elegirTiempo('10 Días')} class="laOpcionComprar">
								<img
									src={relojImg}
									class="imgDetalleProducto"
								/>
								<span style={{marginLeft: 10, textAlign: 'left',}}>
									10 Días<br/>
									<Temporizador
										calculo={10}
									/>
								</span>
							</div>

							<div id="15Dias" onClick={() => elegirTiempo('15 Días')} class="laOpcionComprar">
								<img
									src={relojImg}
									class="imgDetalleProducto"
								/>
								<span style={{marginLeft: 10, textAlign: 'left',}}>
									15 Días<br/>
									<Temporizador
										calculo={15}
									/>
								</span>
							</div>

							<div id="1Mes" onClick={() => elegirTiempo('1 Mes')} class="laOpcionComprar">
								<img
									src={relojImg}
									class="imgDetalleProducto"
								/>
								<span style={{marginLeft: 10, textAlign: 'left',}}>
									1 Mes<br/>
									<Temporizador
										calculo={'1Mes'}
									/>
								</span>
							</div>

						</div>
					
					</div>

					<div class="opcionesProducto">

						<h5 class="tituloOpcionesProducto">Cuántas pantallas quieres comprar?</h5>
						<div class="hr"></div>

						<div class="opcionesElegir">

							<div id="1Pantalla" onClick={() => elegirTipoCuenta('1 Pantalla')} class="laOpcionComprar">
								<img
									src={monitorImg}
									class="imgDetalleProducto"
								/>
								<span style={{marginLeft: 10, textAlign: 'left',}}>
									1 PANTALLA
								</span>
							</div>

							<div id={"cuentaCompleta-"+idProducto} onClick={() => elegirTipoCuenta('Cuenta Completa')} class="laOpcionComprar">
								<img
									src={devices}
									class="imgDetalleProducto"
								/>
								<span style={{marginLeft: 10, textAlign: 'center',}}>
									CUENTA COMPLETA
								</span>
							</div>

						</div>
					
					</div>

					<div style={{textAlign: 'left', marginLeft: 20,}} class="form-check">
					  	<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
					  	<label class="form-check-label" for="flexCheckDefault">
					    	Estoy de acuerdo con los <span onClick={() => {
			    				CambioMostrarModalTerminos(true);
			    			}} style={{color: '#3ad32b', fontWeight: 'bold', cursor: 'pointer',}}> términos y condiciones del servicio</span>
					  	</label>
					</div>

					<button onClick={pagarProducto} style={{background: 'rgb(21, 128, 61)', width: '100%', marginTop: 20,}} class="btn btn-primary">
						<img
							src={carritoBlanco}
							class="imgBoton"
						/>
						PAGAR ${precioProducto}
					</button>

			    </div>
			</Modal>

			<Modal mostrar={mostrarModalTerminos}>
		        
				<p onClick={() => {
			    	CambioMostrarModalTerminos(false);
			    }} class="equix">X</p>
			    <div class="modalComprarProducto">
			    	<h3 style={{color: '#fff', fontWeight: 'bold'}}>Términos y condiciones del servicio</h3><br/>
			    	<p style={{color: '#fff',}}>
			    		<span style={{fontWeight: 'bold'}}>PERDERÁ GARANTÍA SI INCUMPLE LAS SIGUIENTES REGLAS:</span><br/><br/>

						1.- Intento y/o cambio de correo o contraseña.<br/>

						2.- Cambio de clave o nombre del perfil sin autorización.<br/>

						3.- Cualquier Modificación que realice a su perfil, y otros perfiles sin autorización<br/>

						4.- PROHIBIDO COMPARTIR LA CUENTA, solo puedes ingresar en 1 solo dispositivo a la ves (si usas más de un dispositivo se cerrará sesión automáticamente y tienes riesgo de perder el acceso a la cuenta).<br/><br/>

						<span style={{fontWeight: 'bold'}}>RECUERDE:</span><br/><br/>

						Su compra la hace por un perfil, use el perfil adquirido porque el resto de la cuenta es de otros clientes.
			    	</p>
				</div>

		    </Modal>

		    <Modal mostrar={mostrarModalPreguntaComprar}>
				<div style={{padding: 20,}}>
				    <h5 class="tituloIniciarSesion sinMargenes">¿Desea compra esta gift card {nombreProducto}?</h5>
				    <div style={{textAlign: 'center',}}>
				        <button class="btn btn-primary" onClick={pagarProducto}>
				        	<img
								src={carritoBlanco}
								class="imgBoton"
							/>
							PAGAR ${precioProducto}
				        </button>
				        <button onClick={() => {
				            CambioMostrarModalPreguntaComprar(false);
				            CambioDatosProducto(false);
					    	CambioFotoProducto('');
							CambioNombreProducto('');
							CambioRegionProducto('');
							CambioPrecioProducto(0);
							CambioTiempoCompra('');
							CambioTipoCuenta('');
				        }} style={{margin: 5,}} class="btn btn-danger">No</button>
				    </div>
				</div>
			</Modal>
		</AnimatedPage>
	);
}

export default ClienteRevendedorScreen;
import React, { useState, useEffect } from 'react';
import './footer-styles.css';
import {logoMenu, instagram} from '../../global/imgs';
import {Link} from 'react-router-dom';
import {Modal} from '../../components/modals/modals';

export function Footer(){

	let fecha = new Date();

	const [mostrarModalTerminos, CambioMostrarModalTerminos] = useState(false);

	return(
		<div class="footer">

			<div class="filaFooter">
				<Link to='/'>
					<img
						class="logoMenu"
						src={logoMenu}
					/>
				</Link>

				<img
					src={instagram}
					style={{width: 30, cursor: 'pointer',}}
					onClick={() => window.location.href = 'https://www.instagram.com/venetgoplayca/'}
				/>

			</div><br/>

			<div class="hr"></div><br/>

			<div class="filaFooter" style={{justifyContent: 'center', flexDirection: 'column'}}>

				<span class="textoDerechos">© {fecha.getFullYear()} Venet Go Play. All Rights Reserved.</span>
				<br/>
				<span onClick={() => {
			    	CambioMostrarModalTerminos(true);
			    }} style={{color: '#3ad32b', fontWeight: 'bold', cursor: 'pointer',}}>Términos y condiciones del servicio</span>
			</div><br/>

			<Modal mostrar={mostrarModalTerminos}>
		        
				<p onClick={() => {
			    	CambioMostrarModalTerminos(false);
			    }} class="equix">X</p>
			    <div class="modalComprarProducto">
			    	<h3 style={{color: '#fff', fontWeight: 'bold'}}>Términos y condiciones del servicio</h3><br/>
			    	<p style={{color: '#fff',}}>
			    		<span style={{fontWeight: 'bold'}}>PERDERÁ GARANTÍA SI INCUMPLE LAS SIGUIENTES REGLAS:</span><br/><br/>

						1.- Intento y/o cambio de correo o contraseña.<br/>

						2.- Cambio de clave o nombre del perfil sin autorización.<br/>

						3.- Cualquier Modificación que realice a su perfil, y otros perfiles sin autorización<br/>

						4.- PROHIBIDO COMPARTIR LA CUENTA, solo puedes ingresar en 1 solo dispositivo a la ves (si usas más de un dispositivo se cerrará sesión automáticamente y tienes riesgo de perder el acceso a la cuenta).<br/><br/>

						<span style={{fontWeight: 'bold'}}>RECUERDE:</span><br/><br/>

						Su compra la hace por un perfil, use el perfil adquirido porque el resto de la cuenta es de otros clientes.
			    	</p>
				</div>

		    </Modal>

		</div>
	);
}
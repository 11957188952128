import React, { useState, useEffect } from 'react';
import './productos-styles.css';
import {MenuApp, MenuAdmin} from '../../../components/menu/menu';
import {validarUsuario} from '../../../global/validarUsuario';
import {folder, categoriasMenu} from '../../../global/imgs';
import {consultarCategorias} from '../../../backend/categorias/categorias';
import {guardarProducto, consultarProductos, eliminarProducto} from '../../../backend/productos/productos';
import Swal from 'sweetalert2';
import {NombreCategorias} from '../../../components/categorias/categorias';
import {Modal} from '../../../components/modals/modals';
import {consultarPaises} from '../../../backend/paises/paises';
import {AnimatedPage} from '../../../components/animacion/animacion';

function AdminProductosScreen() {

  const [usuarioBuscado, CambioUsuarioBuscado] = useState(false);
  const [nombre, CambioNombre] = useState('');
  const [precio, CambioPrecio] = useState('');
  const [categorias, CambioCategorias] = useState([]);
  const [busquedaCategorias, CambioBusquedaCategorias] = useState(false);
  const [color, CambioColor] = useState('');
  const [busquedaProductos, CambioBusquedaProductos] = useState(false);
  const [productos, CambioProductos] = useState([]);
  const [cuentas, CambioCuentas] = useState([]);
  const [tarjetas, CambioTarjetas] = useState([]);
  const [limiteProductos, CambioLimiteProductos] = useState(20);
  const [idProducto, CambioIdProducto] = useState(false);
  const [mostrarModalPreguntaProducto, CambioMostrarModalPreguntaProducto] = useState(false);
  const [precioRevendedores, CambioPrecioRevendedores] = useState('');
  const [precioCuentaCompleta, CambioPrecioCuentaCompleta] = useState('');
  const [precioCuentaCompletaRevendedores, CambioPrecioCuentaCompletaRevendedores] = useState('');
  const [paises, CambioPaises] = useState([]);
  const [busquedaPaises, CambioBusquedaPaises] = useState(false);

  useEffect(() =>{

    if (usuarioBuscado == false) {

      ponerUsuario();
      CambioUsuarioBuscado(true);
    }

    if (busquedaCategorias == false) {

      consultarCategorias().then(response =>{

        CambioCategorias(response);
      });

      CambioBusquedaCategorias(true);
    }

    if (busquedaProductos == false) {

      document.getElementById('cuenta').checked = true;

      consultarProductos().then(response =>{

        let porTipo = document.getElementById('tipoMostrar').value;
        let masCuentas = [];
        let masTarjetas = [];

        let cero = 0;

        response.forEach(element =>{

          if (element.data.tipo == 'cuenta') {

            if (cero <= limiteProductos) {
                masCuentas.push(element);
            }
          }else if (element.data.tipo == 'tarjeta') {

            if (cero <= limiteProductos) {
                masTarjetas.push(element);
            }
          }

          cero++;
        });

        if (response.length > 20) {
          document.getElementById('verMas').setAttribute('style', 'display: block; margin: auto; margin-top: 20px;');
        }

        CambioProductos(response);
        CambioCuentas(masCuentas);
        CambioTarjetas(masTarjetas);
      });

      CambioBusquedaProductos(true);
    }

    if (busquedaPaises == false) {

      consultarPaises().then(response =>{

        CambioPaises(response);
      })
      CambioBusquedaPaises(true);
    }
  })

  const ponerUsuario = async function(){

    let elUsuario = await validarUsuario();

    if (elUsuario != false) {

      if (elUsuario.rol == 'admin' || elUsuario.rol == 'subAdmin') {

      }else{

        window.location.href = '/';
      }
    }else{

      window.location.href = '/';
    }
  }

  const visualizarNombreArchivo = async function(idElement, idLabel){

    let fileInput = document.getElementById(idElement);   
    let filename = fileInput.files[0].name; console.log(filename);
    document.getElementById(idLabel).innerHTML = filename;
  }

  const procesarProducto = async function(){

      Swal.fire({
        html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
            '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
            '</div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
      });

      let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
      let id = "";
        for (let i=0; i<20; i++) id +=characters.charAt(Math.floor(Math.random()*characters.length));

      let camposValidos = true;

      let tipo = '';

      let region = document.getElementById('region').value;

      if (document.getElementById('cuenta').checked) {

        tipo = 'cuenta';

        if (nombre === '' || region === '' || precio === '' || precioCuentaCompleta === '' || precioRevendedores === '' || precioCuentaCompletaRevendedores === '' || document.getElementById('categoria').value === '' || color === '' || tipo === '') {
          
          camposValidos = false;
        }
      }else if (document.getElementById('tarjeta').checked) {

        tipo = 'tarjeta';

        if (nombre === '' || region === '' || precio === '' || precioRevendedores === '' || document.getElementById('categoria').value === '' || color === '' || tipo === '') {
          
          camposValidos = false;
        }
      }

      if (camposValidos == false) {
        Swal.fire({
            icon: 'info',
            title: 'Complete todos los campos para continuar.',
        });
      }else{

        let elId = '';

        if (idProducto != false) {

          elId = idProducto;
        }else{

          elId = id;
        }

        let data = {
          activo: true,
          nombre: nombre,
          precio: precio,
          precioCuentaCompleta: precioCuentaCompleta,
          precioRevendedores: precioRevendedores,
          precioCuentaCompletaRevendedores: precioCuentaCompletaRevendedores,
          categoria: document.getElementById('categoria').value,
          color: color,
          tipo: tipo,
          region: region,
          foto: document.getElementById('imgCategoria').files[0],
        };

        guardarProducto(elId, data).then(response =>{
          window.location.reload();
        })
      }
  }

  const verMas = async function(){

    let porTipo = document.getElementById('tipoMostrar').value;

    let limite = limiteProductos + 20;

    let masCuentas = [];
    let masTarjetas = [];

    let cero = 0;

    productos.forEach(element =>{

      if (element.data.tipo == 'cuenta') {

        if (cero <= limite) {
          masCuentas.push(element);
        }
      }else if (element.data.tipo == 'tarjeta') {

        if (cero <= limite) {
          masTarjetas.push(element);
        }
      }

      cero++;
    });


    CambioCuentas(masCuentas);
    CambioTarjetas(masTarjetas);
    CambioLimiteProductos(limite);
  }

  const prepararDatosParaEditar = async function(item){

    document.getElementById('tituloHeaderLogin').innerHTML = 'EDITAR PRODUCTO';
    document.getElementById('eleccionTipo').setAttribute('style', 'display: none;');
    document.getElementById('tablaProductos').setAttribute('style', 'display: none;');
    document.getElementById('botonProceso').innerHTML = 'EDITAR';
    document.getElementById('botonCancerlar').setAttribute('style', 'background: #b91c1c; width: 100%; margin-top: 20px;');

    CambioIdProducto(item.id);

    CambioNombre(item.data.nombre);
    document.getElementById('nombre').value = item.data.nombre;

    CambioPrecio(item.data.precio);
    document.getElementById('precio').value = item.data.precio;

    CambioPrecioCuentaCompleta(item.data.precioCuentaCompleta);
    document.getElementById('precioCuentaCompleta').value = item.data.precioCuentaCompleta;

    CambioPrecioRevendedores(item.data.precioRevendedor);
    document.getElementById('precioRevendedores').value = item.data.precioRevendedor;

    CambioPrecioCuentaCompletaRevendedores(item.data.precioCuentaCompletaRevendedor);
    document.getElementById('precioCuentaCompletaRevendedores').value = item.data.precioCuentaCompletaRevendedor;

    document.getElementById('region').value = item.data.region;

    document.getElementById('categoria').value = item.data.categoria;

    CambioColor(item.data.color);
    document.getElementById('color').value = item.data.color;

    if (item.data.tipo == 'cuenta') {

      document.getElementById('cuenta').checked = true;
      document.getElementById('esconderCompleta').setAttribute('style', '');
      document.getElementById('esconderCompletaRevendedores').setAttribute('style', '');
    }else if (item.data.tipo == 'tarjeta') {

      document.getElementById('tarjeta').checked = true;
      document.getElementById('esconderCompleta').setAttribute('style', 'display: none;');
      document.getElementById('esconderCompletaRevendedores').setAttribute('style', 'display: none;');
    }
  }

  const removeProducto = async function(){

    Swal.fire({
      html: '<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center;">'+
          '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>'+
          '</div>',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });

    eliminarProducto(idProducto).then(response =>{
      window.location.reload();
    })
  }

  const elegirTipoDeProducto = async function(unTipo){

    document.getElementById(unTipo).checked = true;

    if (unTipo == 'cuenta') {

      document.getElementById('esconderCompleta').setAttribute('style', '');
      document.getElementById('esconderCompletaRevendedores').setAttribute('style', '');
    }else if (unTipo == 'tarjeta') {

      document.getElementById('esconderCompleta').setAttribute('style', 'display: none;');
      document.getElementById('esconderCompletaRevendedores').setAttribute('style', 'display: none;');
    }
  }

  const verPorTipo = async function(){

    let porTipo = document.getElementById('tipoMostrar').value;

    if (porTipo == 'cuenta') {

      document.getElementById('tbodyCuentas').setAttribute('style', '');
      document.getElementById('tbodyTarjetas').setAttribute('style', 'display: none;');
    }else if (porTipo == 'tarjeta') {

      document.getElementById('tbodyCuentas').setAttribute('style', 'display: none;');
      document.getElementById('tbodyTarjetas').setAttribute('style', '');
    }
  }

  return(
    <AnimatedPage duracion={0.5}>
      <MenuApp/>
      <div class="gridApp">
        <MenuAdmin/>
        <div class="container">

          <div class="row">
            <div class="col-sm">

              <div class="containerTituloSeccion">
                <img
                  src={folder}
                  class="imgTituloSeccion"
                />
                <h4 class="tituloSeccion">PRODUCTOS</h4>
              </div>

            </div>
          </div><br/>

          <div class="row">
            <div class="col-sm">

              <div class="form" style={{margin: 0,}}>
                  <div class="headerFormLogin">
                    <img
                      src={folder}
                      class="userPlus"
                    />
                    <h4 id="tituloHeaderLogin" class="tituloHeaderLogin">AGREGAR PRODUCTOS</h4>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Tipo *</label> <br/>
                      <div class="form-check">
                        <input onClick={() => elegirTipoDeProducto('cuenta')} onChange={() => elegirTipoDeProducto('tarjeta')} class="form-check-input" type="radio" name="flexRadioDefault" id="cuenta" />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Cuenta Streaming
                        </label>
                      </div>
                      <div class="form-check">
                        <input onClick={() => elegirTipoDeProducto('tarjeta')} onChange={() => elegirTipoDeProducto('tarjeta')} class="form-check-input" type="radio" name="flexRadioDefault" id="tarjeta" />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Tarjeta de regalo
                        </label>
                      </div>
                  </div>

                  <div class="mb-3 containerInput">
                      <input onChange={() => CambioNombre(document.getElementById('nombre').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="text" class="form-control inputLogin" id="nombre" placeholder="Nombre *" />
                  </div>

                  <div class="mb-3 containerInput">
                      <input onChange={() => CambioPrecio(document.getElementById('precio').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="precio" placeholder="Precio *" />
                  </div>

                  <div class="mb-3 containerInput" id="esconderCompleta">
                      <input onChange={() => CambioPrecioCuentaCompleta(document.getElementById('precioCuentaCompleta').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="precioCuentaCompleta" placeholder="Precio Cuenta Completa *" />
                  </div>

                  <div class="mb-3 containerInput">
                      <input onChange={() => CambioPrecioRevendedores(document.getElementById('precioRevendedores').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="precioRevendedores" placeholder="Precio Revendedores *" />
                  </div>

                  <div class="mb-3 containerInput"  id="esconderCompletaRevendedores">
                      <input onChange={() => CambioPrecioCuentaCompletaRevendedores(document.getElementById('precioCuentaCompletaRevendedores').value)} style={{backgroundColor: '#404040', color: '#fff',}} type="number" class="form-control inputLogin" id="precioCuentaCompletaRevendedores" placeholder="Precio Cuenta Completa Revendedores *" />
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Región *</label> <br/>
                      <select id="region" class="form-select form-select-lg mb-3" aria-label="Large select example">
                        <option value="">Región *</option>
                        {paises.map((item) =>(
                          <option value={item.id}>{item.data.nombre}</option>
                        ))}
                      </select>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Categoría *</label> <br/>
                      <select id="categoria" class="form-select form-select-lg mb-3" aria-label="Large select example">
                        <option value="">Seleccione categoría</option>
                        {categorias.map((item) =>(
                          <option value={item.id}>{item.data.nombre}</option>
                        ))}
                      </select>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Color *</label> <br/>
                      <input onChange={() => CambioColor(document.getElementById('color').value)} style={{backgroundColor: '#404040', color: '#fff', height: 50,}} type="color" class="form-control inputLogin" id="color" placeholder="Color *" />
                  </div>

                  <label for="imgCategoria" style={{background: '#15803d', width: '100%', marginTop: 20,}} class="btn btn-primary">
                    <span id="labelImgCategoria">SUBIR IMAGEN</span>
                    <input onChange={() => visualizarNombreArchivo('imgCategoria', 'labelImgCategoria')} type="file" id="imgCategoria" style={{display: 'none',}} accept="image/*" />
                  </label>

                  <button id="botonProceso" onClick={procesarProducto} style={{background: '#1d4ed8', width: '100%', marginTop: 20,}} class="btn btn-primary">
                    AGREGAR
                  </button>

                  <button id="botonCancerlar" onClick={() => window.location.reload()} style={{background: '#b91c1c', width: '100%', marginTop: 20, display: 'none',}} class="btn btn-primary">
                    CANCELAR
                  </button>
              </div>

            </div>
          </div><br/>

          <div class="row">
            <div class="col-sm" id="eleccionTipo">

              <div class="mb-3">
                <label class="form-label">Ver por tipo:</label> <br/>
                  <select onChange={verPorTipo} id="tipoMostrar" class="form-select form-select-lg mb-3" aria-label="Large select example">
                    <option value="cuenta">Cuentas streaming</option>
                    <option value="tarjeta">Tajetas de regalo</option>
                  </select>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-sm" style={{overflow: 'auto',}}>

              <table id="tablaProductos" class="table">
                <thead>
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Imagen</th>
                    <th scope="col">Precio</th>
                    <th scope="col">Categoría</th>
                    <th scope="col">Región</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Color</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>

                <tbody id="tbodyCuentas">
                  {cuentas.map((item) =>(
                    <tr>
                      <td>{item.data.nombre}</td>
                      <td>
                        <img
                          src={item.data.foto}
                          style={{width: 100,}}
                        />
                      </td>
                      <td>${item.data.precio}</td>
                      <td>
                        <NombreCategorias
                          idCategoria={item.data.categoria}
                        />
                      </td>
                      <td>
                        {item.data.region}
                      </td>
                      <td>
                        {item.data.tipo}
                      </td>
                      <td>
                        <div style={{width: 30, height: 30, background: item.data.color}}></div>
                      </td>
                      <td>
                        <button onClick={() => prepararDatosParaEditar(item)} class="btn btn-success">
                          Editar
                        </button>
                        <button onClick={() => {
                          CambioMostrarModalPreguntaProducto(true);
                          CambioIdProducto(item.id);
                        }} class="btn btn-danger">
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tbody id="tbodyTarjetas" style={{display: 'none',}}>
                  {tarjetas.map((item) =>(
                    <tr>
                      <td>{item.data.nombre}</td>
                      <td>
                        <img
                          src={item.data.foto}
                          style={{width: 100,}}
                        />
                      </td>
                      <td>${item.data.precio}</td>
                      <td>
                        <NombreCategorias
                          idCategoria={item.data.categoria}
                        />
                      </td>
                      <td>
                        {item.data.region}
                      </td>
                      <td>
                        {item.data.tipo}
                      </td>
                      <td>
                        <div style={{width: 30, height: 30, background: item.data.color}}></div>
                      </td>
                      <td>
                        <button onClick={() => prepararDatosParaEditar(item)} class="btn btn-success">
                          Editar
                        </button>
                        <button onClick={() => {
                          CambioMostrarModalPreguntaProducto(true);
                          CambioIdProducto(item.id);
                        }} class="btn btn-danger">
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>

          <div class="row">
            <div class="col-sm">

              <button style={{display: 'none',}} onClick={verMas} class="btn btn-primary" id="verMas">
                Ver más
              </button><br/><br/>

            </div>
          </div>

        </div>
      </div>

      <Modal mostrar={mostrarModalPreguntaProducto}>

          <div style={{padding: 20,}}>
            <h5 class="tituloIniciarSesion sinMargenes">¿Desea eliminar esta producto?</h5>
              <div style={{textAlign: 'center',}}>
                <button class="btn btn-primary" onClick={removeProducto}>Si</button>
                <button onClick={() => {
                  CambioMostrarModalPreguntaProducto(false);
                  CambioIdProducto(false);
                }} style={{margin: 5,}} class="btn btn-danger">No</button>
              </div>
          </div>
      </Modal>
    </AnimatedPage>
  );
}

export default AdminProductosScreen;